<div class="body">
    <form [formGroup]="resetPasswordForm">
      @switch (pageAction) {
        @case (enumAccountSetupPageAction.resetPassword) {
          <h1 id="login-text">Réinitialisation du mot de passe</h1>
          
          <input hidden="true" pInputText id="email" formControlName="email" />
          <div class="form-field">
            <span class="p-float-label">
              <p-password formControlName="password" [feedback]="false" [toggleMask]="true"></p-password>
              <label for="password">Mot de passe</label>
            </span>
          </div>
          <div class="form-field">
            <span class="p-float-label">
              <p-password formControlName="passwordConfirmation" [feedback]="false" [toggleMask]="true"></p-password>
              <label for="passwordConfirmation">Confirmer le mot de passe</label>
            </span>
          </div>
          <div class="form-button">
            <button [disabled]="isReseting" class="button" (click)="resetPassword()">Confirmer</button>
            @if (isReseting) {
              <p-progressSpinner class="spinner"></p-progressSpinner>
            }
          </div>
        }
        @case (enumAccountSetupPageAction.setFirstPassword) {
          <h1 id="login-text">Création de votre premier mot de passe</h1>
          <input hidden="true" pInputText id="email" formControlName="email" />
          <div class="form-field">
            <span class="p-float-label">
              <p-password formControlName="password" [feedback]="false" [toggleMask]="true"></p-password>
              <label for="password">Mot de passe</label>
            </span>
          </div>
          <div class="form-field">
            <span class="p-float-label">
              <p-password formControlName="passwordConfirmation" [feedback]="false" [toggleMask]="true"></p-password>
              <label for="passwordConfirmation">Confirmer le mot de passe</label>
            </span>
          </div>
          <div class="form-button">
            <button [disabled]="isReseting" class="button" (click)="confirmUserAndSetFirstPassword()">Confirmer</button>
            @if (isReseting) {
              <p-progressSpinner class="spinner"></p-progressSpinner>
            }
          </div>

        }
        @case (enumAccountSetupPageAction.email) {
          <h1 id="login-text">Mot de passe oublié</h1>
          <p>Saisir votre e-mail pour recevoir un lien de réinitialisation.</p>
          <div class="form-field">
            <span class="p-float-label">
              <input pInputText id="email" formControlName="email" />
              <label htmlFor="email">E-mail</label>
            </span>
          </div>
          
          <div class="form-button">
            <button [disabled]="isReseting" class="button" (click)="sendResetPasswordEmail()">Confirmer</button>
            @if (isReseting) {
              <p-progressSpinner class="spinner"></p-progressSpinner>
            }
          </div>
        }
      }
    </form>
  </div>

  