<p-toast position="top-center"></p-toast>
<div class="app">
  
  <div class="header">
    <h2>CMS OHRIZON 
      @if (displayNavbar) {
       - {{ getProjectName() }}
      }
    </h2>
      
    @if (displayNavbar) {
      <span class="userName" 
      (mouseenter)="showPanel = true" 
      (mouseleave)="showPanel = false">{{ getUserInitials() }}</span>
    }

  </div>
  @if (showPanel) {
    <div class="custom-panel">
      <p>Utilisateur : {{ getUserFullName() }}</p>
      <p>Email : {{ getUserEmail() }}</p>
    </div>
  }
  <div class="main-container">

    @if (displayNavbar) {
      <div class="navbar">
        <app-navbar></app-navbar>
      </div>
    }
  
    <div class="router-outlet-container">
      <router-outlet></router-outlet>
    </div>
  
    <app-file-upload-overlay></app-file-upload-overlay>
  </div>
</div>
