import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { LeafletModule } from "@bluehalo/ngx-leaflet";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

// Pages
import { AdministationPageComponent } from "./Pages/Administration/administation-page/administation-page.component";
import { AreaManagementPageComponent } from "./Pages/Area-Management/area-management-page/area-management-page.component";
import { ChangelogPageComponent } from "./Pages/Changelog/changelog-page/changelog-page.component";
import { DocumentLibraryPageComponent } from "./Pages/Document-Library/document-library-page/document-library-page.component";
import { HomePageComponent } from "./Pages/Home/home-page/home-page.component";
import { ItineraryManagementPageComponent } from "./Pages/Itinerary-Management/itinerary-management-page/itinerary-management-page.component";
import { LoginPageComponent } from "./Pages/Login/login-page/login-page.component";
import { ModuleManagementPageComponent } from "./Pages/Module-Management/module-management-page/module-management-page.component";
import { PointOfInterestManagementPageComponent } from "./Pages/PointOfInterest-Management/point-of-interest-management-page/point-of-interest-management-page.component";
import { AccountSetupPageComponent } from "./Pages/Account-setup/account-setup-page/account-setup-page/account-setup-page.component";
import { ProjectSelectionPageComponent } from "./Pages/Project-Selection/project-selection-page/project-selection-page.component";

// Popups
import { AddLanguagePopupComponent } from "./Popups/add-language-popup/add-language-popup/add-language-popup.component";
import { ConfirmationPopupComponent } from "./Popups/confirmation-popup/confirmation-popup.component";
import { MediaManagementPopupComponent } from "./Popups/Media-Management/media-management-popup/media-management-popup.component";
import { ModuleCustomPopupComponent } from "./Popups/module-custom-popup/module-custom-popup.component";
import { PublicationPopupComponent } from "./Popups/publication-popup/publication-popup.component";

// Components
import { AddedModuleCardComponent } from "./Pages/PointOfInterest-Management/added-module-card/added-module-card.component";
import { AssociatedMediaCardComponent } from "./Pages/Module-Management/associated-media-card/associated-media-card.component";
import { AssociatedMediaListComponent } from "./Pages/Module-Management/associated-media-list/associated-media-list.component";
import { AvailableModuleCardComponent } from "./Pages/PointOfInterest-Management/available-module-card/available-module-card.component";
import { CompletionStateComponent } from "./ReusableComponents/completion-state/completion-state.component";
import { FileUploadOverlayComponent } from "./ReusableComponents/file-upload-overlay/file-upload-overlay.component";
import { ItineraryCardComponent } from "./Pages/Area-Management/itinerary-card/itinerary-card.component";
import { ItineraryListComponent } from "./Pages/Area-Management/itinerary-list/itinerary-list.component";
import { ItineraryMultilanguageContentComponent } from "./Pages/Itinerary-Management/itinerary-multilanguage-content/itinerary-multilanguage-content.component";
import { ItineraryConfigurationComponent } from "./Pages/Itinerary-Management/itinerary-configuration/itinerary-configuration.component";
import { PointOfInterestCardComponent } from "./Pages/Itinerary-Management/point-of-interest-card/point-of-interest-card.component";
import { PointOfInterestConfigurationComponent } from "./Pages/PointOfInterest-Management/point-of-interest-configuration/point-of-interest-configuration.component";
import { PointOfInterestListComponent } from "./Pages/Itinerary-Management/point-of-interest-list/point-of-interest-list.component";
import { PointOfInterestMultiLanguageContentComponent } from "./Pages/PointOfInterest-Management/point-of-interest-multi-language-content/point-of-interest-multi-language-content.component";
import { MediaAssociationComponent } from "./Popups/media-association/media-association.component";
import { ModuleConfigurationComponent } from "./Pages/Module-Management/module-configuration/module-configuration.component";
import { ModuleCustomComponent } from "./Pages/Module-Management/module-custom/module-custom.component";
import { ModuleGalleryComponent } from "./Pages/Module-Management/module-gallery/module-gallery.component";
import { ModuleListComponent } from "./Pages/PointOfInterest-Management/module-list/module-list.component";
import { ModuleMultilanguageContentComponent } from "./Pages/Module-Management/module-multilanguage-content/module-multilanguage-content.component";
import { ModuleQuizComponent } from "./Pages/Module-Management/module-quiz/module-quiz.component";
import { NavbarComponent } from "./NavBar/navbar/navbar.component";
import { PublicationStateComponent } from "./ReusableComponents/publication-state/publication-state.component";
import { LanguageStateComponent } from "./ReusableComponents/language-state/language-state.component";
import { PointOfInterestMapComponent } from "./ReusableComponents/point-of-interest-map/point-of-interest-map.component";

// Interceptors
import { AuthInterceptor } from "./Interceptors/auth.interceptor";

// Services
import { ModuleService } from "./Services/Module-Service/module.service";
import { UploadFilesService } from "./Services/Upload-Service/upload-files.service";

// PrimeNg imports
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InputTextModule } from "primeng/inputtext";
import { PasswordModule } from "primeng/password";
import { ToastModule } from "primeng/toast";
import { DialogModule } from "primeng/dialog";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MultiSelectModule } from "primeng/multiselect";
import { MessageService } from "primeng/api";
import { DropdownModule } from "primeng/dropdown";
import { InputSwitchModule } from "primeng/inputswitch";
import { TableModule } from "primeng/table";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ProgressBarModule } from "primeng/progressbar";
import { CheckboxModule } from "primeng/checkbox";
import { PaginatorModule } from "primeng/paginator";
import { TreeTableModule } from "primeng/treetable";
import { RadioButtonModule } from "primeng/radiobutton";
import { SplitButtonModule } from "primeng/splitbutton";

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AreaManagementPageComponent,
    ItineraryCardComponent,
    ItineraryListComponent,
    NavbarComponent,
    LoginPageComponent,
    ItineraryManagementPageComponent,
    ItineraryMultilanguageContentComponent,
    PointOfInterestCardComponent,
    PointOfInterestListComponent,
    ConfirmationPopupComponent,
    PointOfInterestManagementPageComponent,
    ItineraryConfigurationComponent,
    PointOfInterestConfigurationComponent,
    PointOfInterestMultiLanguageContentComponent,
    ModuleListComponent,
    AddedModuleCardComponent,
    AvailableModuleCardComponent,
    DocumentLibraryPageComponent,
    MediaManagementPopupComponent,
    ModuleManagementPageComponent,
    ModuleMultilanguageContentComponent,
    ModuleConfigurationComponent,
    ModuleGalleryComponent,
    MediaAssociationComponent,
    PublicationPopupComponent,
    AssociatedMediaListComponent,
    AssociatedMediaCardComponent,
    ModuleQuizComponent,
    CompletionStateComponent,
    AddLanguagePopupComponent,
    AdministationPageComponent,
    ModuleCustomComponent,
    FileUploadOverlayComponent,
    ModuleCustomPopupComponent,
    ChangelogPageComponent,
    PublicationStateComponent,
    LanguageStateComponent,
    PointOfInterestMapComponent,
    AccountSetupPageComponent,
    ProjectSelectionPageComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MessageService },
    ModuleService,
    UploadFilesService,
    provideHttpClient(withInterceptorsFromDi()),
    DatePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    InputTextModule,
    PasswordModule,
    ToastModule,
    DialogModule,
    InputTextareaModule,
    MultiSelectModule,
    DropdownModule,
    InputSwitchModule,
    TableModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    CheckboxModule,
    PaginatorModule,
    TreeTableModule,
    RadioButtonModule,
    SplitButtonModule,
    LeafletModule,
  ],
})
export class AppModule {}
