import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserLogin } from "app/Models/userLogin";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { DataService } from "app/Services/Data-Service/data.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.css"],
  providers: [AuthService],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  token: string = "";
  isLogging: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
    private dataService: DataService
  ) {
    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.authService.userIsLogging.subscribe((result) => {
      this.isLogging = result;
    });
    this.setPageTitle();
  }

  login() {
    this.authService.setUserIsLogging(true);
    const val = this.loginForm.value;
    if (val.email && val.password) {
      const userLogin = new UserLogin();
      userLogin.email = val.email;
      userLogin.password = val.password;
      this.authService.login(userLogin);
    } else {
      this.messageService.add({ severity: "error", summary: "Erreur", detail: "Les identifiants renseignés ne sont pas valides." });
      this.isLogging = false;
    }
  }

  logout() {
    this.authService.logout();
  }

  setPageTitle() {
    document.title = "Login";
  }
}
