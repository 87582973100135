<div class="body">
  <div class="page">
    <h1>Accueil</h1>

    @if (dashboard.itineraries && dashboard.itineraries.length > 0) {
      <div class="itinerary-container">
        @for (itinerary of dashboard.itineraries; track itinerary; let itineraryIndex = $index) {
          <div class="itinerary-section">
            <div class="horizontal-display">
              <div class="identification-section">
                @if (showPointOfInterests[itineraryIndex] === true) {
                  <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier parcours" (click)="showPointOfInterests[itineraryIndex] = false"> </i>
                } @else {
                  <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier parcours" (click)="showPointOfInterests[itineraryIndex] = true"> </i>
                }
                <p class="truncate-one-line" aria-hidden="true" tabindex="0" [title]="'Label du parcours : ' + itinerary.identificationName">Parcours: {{ itinerary.identificationName }}</p>
              </div>

              <div class="single-line">
                <app-publication-state class="publication-state-margin-right" [publicationState]="itinerary.state" [entityType]="enumEntityType.Itinerary"> </app-publication-state>
                <app-completion-state [completionState]="itinerary.readyForPublication" [tooltipTrue]="'Le parcours est complet'" [tooltipFalse]="'Le parcours n\'est pas complet'">
                </app-completion-state>

                <a routerLink="/{{ this.getProjectName() }}/itinerary-management/{{ itinerary.id }}" title="Visualiser le parcours" class="action-buttons-item">
                  <i class="bi bi-pencil"></i>
                </a>
              </div>
            </div>

            @if (showPointOfInterests[itineraryIndex] === true && itinerary.pointOfInterests !== undefined && itinerary.pointOfInterests.length === 0) {
              <p class="POI-label">Aucune étape n'est associée à ce parcours</p>
            } @else if (showPointOfInterests[itineraryIndex] === true && itinerary.pointOfInterests) {
              @for (pointOfInterest of itinerary.pointOfInterests; track pointOfInterest; let pointOfInterestIndex = $index) {
                <div class="horizontal-line"></div>

                <div class="horizontal-display">
                  <div class="identification-section POI-label">
                    @if (pointOfInterest.showModules[pointOfInterestIndex] === true) {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier l'étape" (click)="pointOfInterest.showModules[pointOfInterestIndex] = false"> </i>
                    } @else {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier l'étape" (click)="pointOfInterest.showModules[pointOfInterestIndex] = true"> </i>
                    }

                    <p aria-hidden="true" tabindex="0" class="truncate-one-line" [title]="'Label de l\'étape : ' + pointOfInterest.identificationName">
                      Étape: {{ pointOfInterest.identificationName }}
                    </p>
                  </div>
                  <div class="single-line">
                    <app-completion-state [completionState]="pointOfInterest.readyForPublication" [tooltipTrue]="'L\'étape est complète'" [tooltipFalse]="'L\'étape n\'est pas complète'">
                    </app-completion-state>

                    <a routerLink="/{{ this.getProjectName() }}/point-of-interest-management/{{ pointOfInterest.id }}" title="Visualiser l'étape" class="action-buttons-item">
                      <i class="bi bi-pencil"></i>
                    </a>
                  </div>
                </div>

                @if (pointOfInterest.showModules[pointOfInterestIndex] === true && pointOfInterest.modules?.length === 0) {
                  <p class="module-label">Aucune activité n'est associée à l'étape.</p>
                } @else if (pointOfInterest.showModules[pointOfInterestIndex] === true) {
                  @for (module of pointOfInterest.modules; track $index) {
                    <div class="horizontal-display">
                      <p class="module-label truncate-one-line" aria-hidden="true" tabindex="0" [title]="'Label de l\'activité : ' + module.identificationName">
                        Activité: {{ module.identificationName }}
                      </p>
                      <div class="single-line">
                        <app-completion-state [completionState]="module.readyForPublication" [tooltipTrue]="'L\'activité est complète'" [tooltipFalse]="'L\'activité n\'est pas complète'">
                        </app-completion-state>

                        <a routerLink="/{{ this.getProjectName() }}/module-management/{{ module.id }}" title="Visualiser l'activité" class="action-buttons-item">
                          <i class="bi bi-pencil"></i>
                        </a>
                      </div>
                    </div>
                  }
                }
              }
            }
          </div>
        }
      </div>
    } @else {
      <p>Aucun parcours n'a été créé pour l'instant. Veuillez vous rendre sur la page de création d'un parcours en suivant le lien ci-dessous:</p>
      <a routerLink="/{{ this.getProjectName() }}/itinerary-management/new">Créer mon premier parcours !</a>
    }
  </div>
</div>
