<div>
  <p-dialog header="Header" [dismissableMask]="true" [modal]="true" [(visible)]="visible" [style]="{ width: '50vw' }" (onHide)="closePopup()">
    <ng-template pTemplate="header">
      @switch (this.confirmTypeAction) {
        @case (this.enumDialogType.Delete) {
          <h2 class="text-xl font-bold">Confirmer la suppression</h2>
        }
        @case (this.enumDialogType.Update) {
          <h2 class="text-xl font-bold">Erreur</h2>
        }
      }
    </ng-template>
    @if (this.confirmTypeAction === this.enumDialogType.Delete && this.entityTypeAction === this.enumEntityType.Itinerary) {
      <p>Êtes-vous surs de vouloir supprimer ce parcours ? Les étapes associées seront aussi supprimées. Les activités associées aux étapes seront cependant conservées et disponibles d'autres étapes. Cette action est irréversible.</p>
    }
    @if (this.confirmTypeAction === this.enumDialogType.Update && this.entityTypeAction === this.enumEntityType.Itinerary) {
      <p>Êtes-vous surs de vouloir modifier ce parcours ? Toutes les modifications effectuées écraseront les données précédentes.</p>
    }
    @if (this.confirmTypeAction === this.enumDialogType.Delete && this.entityTypeAction === this.enumEntityType.PointOfInterest) {
      <p>Êtes-vous surs de vouloir supprimer cette étape ? Cette suppression sera effective lorsque vous appuyerez sur le bouton sauvegarder de la page de gestion du parcours.</p>
    }
    @if (this.confirmTypeAction === this.enumDialogType.Delete && this.entityTypeAction === this.enumEntityType.Marker) {
      <p>Êtes-vous surs de vouloir supprimer ce marqueur ? Cette suppression sera effective lorsque vous appuyerez sur le bouton sauvegarder de la page de gestion du parcours.</p>
    }
    @if (this.confirmTypeAction === this.enumDialogType.Delete && this.entityTypeAction === this.enumEntityType.Media && this.multipleSelection === false) {
      <p>Êtes-vous surs de vouloir supprimer ce média ?</p>
      @if (modulesAssociatedToMedia.length > 0 && modulesAssociatedAreLoading === false) {
        <p>Celui-ci sera supprimé dans l'ensemble des activités suivantes :</p>
        @for (module of modulesAssociatedToMedia; track module.id) {
          <a routerLink="/{{ this.getProjectName() }}/module-management/{{ module.id }}">
            <div class="module-associated">
              <p class="blue-color">{{ module.identificationName }}</p>
              <p>Utilisé {{ module.mediaCount }} fois</p>
              <!-- <i tabIndex="0" aria-hidden="true" class="bi bi-pencil btn-action-file" title="Modifier l'activité" (click)="renameFileAfterUpload()" (keypress)="renameFileAfterUpload()"> </i> -->
            </div>
          </a>
        }
      } @else if (modulesAssociatedAreLoading === true) {
        <div class="spinner-load-modules">
          <p-progressSpinner></p-progressSpinner>
        </div>
      } @else {
        <p>Il n'est actuellement pas utilisé.</p>
      }
    }
    @if (this.confirmTypeAction === this.enumDialogType.Delete && this.entityTypeAction === this.enumEntityType.Media && this.multipleSelection === true) {
      <p>Êtes-vous surs de vouloir supprimer ces médias ? Ceux-ci seront supprimés dans l'ensemble des activités où les éléments sont utilisés.</p>
    }
    @if (this.confirmTypeAction === this.enumDialogType.Delete && this.entityTypeAction === this.enumEntityType.Module && this.multipleSelection === false) {
      <p>Êtes-vous surs de vouloir supprimer cette activité ? Cette action est irréversible.</p>
    }
    @if (this.confirmTypeAction === this.enumDialogType.Delete && this.entityTypeAction === this.enumEntityType.Module && this.multipleSelection === true) {
      <p>Êtes-vous surs de vouloir supprimer ces activités ? Cette action est irréversible.</p>
    }

    <ng-template pTemplate="footer">
      <button class="button confirm-action-button" (click)="confirmAction()">
        @switch (this.confirmTypeAction) {
          @case (this.enumDialogType.Delete) {
            <span title="Supprimer">Supprimer</span>
          }
          @case (this.enumDialogType.Update) {
            <span title="Modifier">Modifier</span>
          }
        }
      </button>
      <button class="button" (click)="visible = false" title="Annuler l'action">Annuler</button>
    </ng-template>
  </p-dialog>
</div>
