import { ChangelogItem } from "app/Models/newFunctionalityChangelog";

// We update this list when we push our changes to the production branch. If you're pushing to prod you have to:
// - Make sure that the list is readable for our final user (non technical people).
// - Add the release date to the first element of the list.
// - Copy the model to the top of the list
//
// Whenever you develop a new functionality, you should add it in the corresponding list:
// - Added for new features.
// - Changed for changes in existing functionality.
// - Fixed for any bug fixes.
// - Removed for now removed features.
// Make sure that the functionality is readable for our final user (non technical people).
// Try to follow the current writing style.
//
// We follow the Keep A Changelog model : https://keepachangelog.com/en/1.1.0/

/* MODEL TO COPY
{
  releaseDate: null,
  functionalities: {
    added: [] ,
    changed: [],
    fixed: [],
    removed: []
  }
},
*/

export const newFunctionalitiesList: ChangelogItem[] = [
  {
    releaseDate: null,
    functionalities: {
      added: [] ,
      changed: [
        "Parcours: fenêtre popup d'ajout de langues améliorée.",
        "Activité: Choix du type d'activité amélioré lors de la création d'une nouvelle activité."
      ],
      fixed: [],
      removed: []
    }
  },
  {
    releaseDate: new Date(2024, 11, 14),
    functionalities: {
      added: [] ,
      changed: [
        "Activité quiz : les questions sont maintenant dépliées par défaut à l'ouverture de la page."
      ],
      fixed: [
        "Activité : correction d'un bug qui empêchait parfois la sauvegarde des activités.",
        "Activité quiz : correction d'un bug d'affichage des données lors de la sauvegarde d'une activité quiz. Les données étaient bien sauvegardées mais il fallait rafraichir la page pour les voir."
      ],
      removed: []
    }
  },
  {
    releaseDate: new Date(2024, 11, 7),
    functionalities: {
      added: [
        "Activité : Lors de la création d'une nouvelle activité depuis la page étape, le champ 'Étape' est rempli automatiquement.",
        "Activité : Ajout de la possibilité d'ajouter plusieurs médias en même temps lorsqu'on crée une activité du type galerie multimédia.",
        "Activité : Il est désormais possible d'ajouter plusieurs médias en même temps",
        "Activité : Possibilité de prévisualiser un média pendant son ajout",
        "Activité : Ajout d'une barre de recherche dans le menu déroulant 'Étape associée'.",
        "Activité : La langue est automatiquement sélectionnée à la création s'il n'y en a qu'une de disponible.",
        "Activité Galerie : Il est désormais possible d'ajouter la traduction d'un média, au lieu d'utiliser le label défini dans la bibliothèque.",
        "Activité et Étape : Ajout d'un d'un fil d'ariane.",
        "Liste d'activités : Ajout d'une barre de recherche .",
        "Liste d'activités : Il est désormais possible d'ouvrir une activité dans un nouvel onglet (ctrl + clic ou clic molette)",
        "Bibliothèque : Ajout d'une barre de recherche.",
        "Bibliothèque : Il est désormais possible d'annuler l'envoi d'un média.",
        "Bibliothèque : Il est désormais d'ajouter des médias à upload avec un copier-coller (ctrl+V).",
        "Parcours : Ajout d'une carte permettant de visualiser et placer les étapes associées à ce parcours.",
        "Étape : Ajout d'une carte permettant de visualiser et placer la localisation de l'étape associée à la page.",
      ],
      changed: [
        "Bibliothèque : Lors de la modification du nom d'un média après un upload, il est désormais possible de valider en appuyant sur entrée",
        "Parcours : Agrandissement du menu déroulant pour sélectionner une étape ou un type d'activité spéciale.",
        "Activité spéciale : Agrandissement du menu déroulant pour sélectionner un type d'activité spéciale.",
        "Général : Le nom de l'onglet du navigateur change désormais en fonction de la page ou l'on se trouve.",
        "Général : L'état déplié/ouvert du menu latéral est désormais sauvegardé.",
      ],
      fixed: [
        "Amélioration du système de publication, il devrait être plus robuste. Auparavant un itinéraire pouvait afficher 'publication en cours' indéfiniment."
      ],
      removed: [],
    },
  },
];
