<div class="body">
  <div class="page">
    <h1>Administration</h1>
    <div class="horizontal-display">
      <p
        class="tab-section"
        aria-hidden="true"
        [class.selected]="administrationType === enumAdministrationType.Projects"
        (click)="changeTab(enumAdministrationType.Projects)"
        (keypress)="changeTab(enumAdministrationType.Projects)">
        Projets
      </p>
      <p
        class="tab-section"
        aria-hidden="true"
        [class.selected]="administrationType === enumAdministrationType.Users"
        (click)="changeTab(enumAdministrationType.Users)"
        (keypress)="changeTab(enumAdministrationType.Users)">
        Utilisateurs
      </p>
      <p
        class="tab-section"
        aria-hidden="true"
        [class.selected]="administrationType === enumAdministrationType.ModuleCustom"
        (click)="changeTab(enumAdministrationType.ModuleCustom)"
        (keypress)="changeTab(enumAdministrationType.ModuleCustom)">
        Modules spéciaux
      </p>
      <p
        class="tab-section"
        aria-hidden="true"
        [class.selected]="administrationType === enumAdministrationType.Languages"
        (click)="changeTab(enumAdministrationType.Languages)"
        (keypress)="changeTab(enumAdministrationType.Languages)">
        Langues
      </p>
    </div>
    <div class="content">
      @switch (administrationType) {
        @case (enumAdministrationType.Projects) {
          @if (modeCreation) {
            <div class="form-container">
              <h3>Nouveau projet</h3>
              <div>
                <span>Nom du projet:</span>
                <input pInputText id="projectName" [formControl]="projectName" />
              </div>
              <div>
                <span>Plateformes:</span>
                <p-multiSelect
                  [showHeader]="false"
                  [options]="buildPlatforms"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Choisir une/des plateformes de build"
                  emptyMessage="Aucune plateforme de build n'a été trouvée."
                  [formControl]="projectBuildPlatforms">
                </p-multiSelect>
              </div>
              <button class="button margin-left" (click)="createProject()">Créer</button>
            </div>
          } @else {
            <button class="button" (click)="modeCreation = true">Créer un projet</button>
          }
          <table>
            <tr>
              <th>Id</th>
              <th class="long-columns-projects-table">Nom</th>
              <th class="long-columns-projects-table">Plateformes de build</th>
              <th class="align-center">Actions</th>
            </tr>
            @for (project of projects; let indexProject = $index; track indexProject) {
              <tr>
                <td class="table-project-item">{{ project.id }}</td>
                @if (indexProjectToModify !== indexProject) {
                  <td class="table-project-item">{{ project.identificationName }}</td>
                  <td>
                    @if (project.buildPlatforms.length > 0) {
                      @for (buildPlatform of project.buildPlatforms; let indexBuildPlatform = $index; track indexBuildPlatform) {
                        <div class="horizontal-display">{{ buildPlatform.name }}</div>
                      }
                    } @else {
                      Aucune plateforme n'est associée au projet.
                    }
                  </td>
                  <td class="align-center">
                    <i tabIndex="0" aria-hidden="true" class="bi bi-pencil btn-action-file" title="Renommer" (click)="modifyProject(indexProject)" (keypress)="modifyProject(indexProject)"> </i>
                  </td>
                } @else {
                  <td>
                    <input pInputText id="projectNameTable" [formControl]="projectNameTable" />
                  </td>
                  <td class="long-columns-projects-table">
                    <p-multiSelect
                      [style]="{ width: '100%' }"
                      [showHeader]="false"
                      [options]="buildPlatforms"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Choisir une/des plateformes de build"
                      emptyMessage="Aucune plateforme de build n'a été trouvée."
                      [formControl]="projectBuildPlatformsTable">
                      <ng-template let-value pTemplate="selectedItems">
                        <div class="elements-p-multiselect">
                          @for (option of value; let optionIndex = $index; track optionIndex) {
                            <p class="truncate-one-line">{{ option.name }}</p>
                            <p>
                              @if (optionIndex !== value.length - 1) {
                                ,
                              }
                            </p>
                          }
                          @if (!value || value.length === 0) {
                            Choisir une plateforme
                          }
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </td>
                  <td class="align-center">
                    <i tabIndex="0" aria-hidden="true" class="bi bi-check-lg btn-action-file" title="Confirmer" (click)="updateProject()" (keypress)="updateProject()"> </i>
                    <i
                      tabIndex="0"
                      aria-hidden="true"
                      class="bi bi-x-lg btn-action-file"
                      title="Abandonner les changements"
                      (click)="resetProjectModification()"
                      (keypress)="resetProjectModification()">
                    </i>
                  </td>
                }
              </tr>
            }
          </table>
        }
        @case (enumAdministrationType.Users) {
          @if (modeCreation) {
            <form [formGroup]="userForm">
              <div class="form-container">
                <h3>Nouvel utilisateur</h3>

                <div class="form-field">
                  <label htmlFor="emailAddress" class="margin-right">Adresse email</label>
                  <input pInputText id="emailAddress" formControlName="emailAddress" style="width: 80%;"/>
                </div>
                <div class="bottom-line"></div>

                <div class="form-field">
                  <label htmlFor="surname" class="margin-right">Nom</label>
                  <input pInputText id="surname" formControlName="surname" style="width: 80%;"/>
                </div>
                <div class="bottom-line"></div>

                <div class="form-field">
                  <label htmlFor="firstName" class="margin-right">Prénom</label>
                  <input pInputText id="firstName" formControlName="firstName" style="width: 80%;"/>
                </div>
                <div class="bottom-line"></div>

                <div class="form-field">
                  <label htmlFor="role">Rôle de l'utilisateur</label>
                  <p-dropdown id="role" [options]="rolesList" formControlName="role" placeholder="Choisir un rôle"> </p-dropdown>
                </div>
                <div class="bottom-line"></div>

                <div class="form-field">
                  <label htmlFor="projectAssociated">Projet associé</label>
                  <!-- <p-dropdown id="projectAssociated" [options]="projects" optionId="id" optionLabel="identificationName" formControlName="projectAssociated" placeholder="Choisir un projet">
                  </p-dropdown> -->
                  <p-multiSelect
                    id="projectAssociated"
                    [options]="projects" 
                    optionValue="id" 
                    optionLabel="identificationName" 
                    formControlName="projectAssociated" 
                    placeholder="Choisir un projet"
                    emptyMessage="Aucun projet n'a été trouvé."
                    [showHeader]="true">
                </p-multiSelect>
                </div>
                <div class="horizontal-display">
                  <button class="button" (click)="createUser()">Ajouter</button>
                  @if (requestInProgress) {
                    <p-progressSpinner class="spinner"></p-progressSpinner>
                  }
                </div>
              </div>
            </form>
          } @else {
            <button class="button" (click)="modeCreation = true">Ajouter un utilisateur</button>
          }
          <table>
            <tr>
              <th>Id</th>
              <th>Adresse e-mail</th>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Role</th>
              <th>Project</th>
              <th>Email confirmé</th>
              <th>Mot de passe ajouté</th>
              <th>Actions</th>
            </tr>
            @for (user of users; track $index) {
              <tr class="user-table">
                <td class="table-user-item">{{ user.id }}</td>
                <td class="table-user-item">{{ user.email }}</td>
                @if (indexUserToModify == $index) {   
                  <td>
                    <input pInputText id="surname" [formControl]="userSurname" />
                  </td>
                  <td>
                    <input pInputText id="firstName" [formControl]="userFirstName" />
                  </td>               
                  <td>
                    <p-dropdown
                      [options]="rolesList"
                      [formControl]="userRole">
                    </p-dropdown>
                  </td>
                  <td>
                    <p-multiSelect
                      [options]="projects"
                      optionLabel="identificationName"
                      optionValue="id"
                      [formControl]="userProjects"
                      [showHeader]="false"
                      placeholder="Choisir un projet"
                      emptyMessage="Aucun projet n'a été trouvé.">
                    </p-multiSelect>
                  </td>
                } @else {
                  <td class="table-user-item">{{ user.surname }}</td>
                  <td class="table-user-item">{{ user.firstName }}</td>
                  <td class="table-user-item">{{ user.role }}</td>
                  <td class="table-user-item">
                    @for (project of user.projects; let indexProject = $index; track indexProject) {
                      <p class="project-name-user">{{ user.projects[indexProject].identificationName }}</p>
                    }  
                  </td>
                }
                <td class="table-user-item">
                  <span class="table-chip" [ngClass]="user.emailConfirmed ? 'green-state' : 'red-state'">{{ displayBoolean(user.emailConfirmed) }}</span>
                  @if (!user.emailConfirmed) {
                    <a class="send-email-link" (click)="sendConfirmAndSetPasswordEmail($index, user.email)">Renvoyer l'invitation</a>
                    @if (sendConfirmationEmailClient === $index) {
                      <p-progressSpinner class="spinner"></p-progressSpinner>
                    }
                  }
                </td>
                <td class="table-user-item"><span class="table-chip" [ngClass]="user.passwordSet ? 'green-state' : 'red-state'">{{ displayBoolean(user.passwordSet!) }}</span></td>
                @if (indexUserToModify == $index) {
                  <td class="align-center">
                    <i tabIndex="0" aria-hidden="true" class="bi bi-check-lg btn-action-file" title="Confirmer" (click)="updateUser()" (keypress)="updateUser()"> </i>
                    <i
                      tabIndex="0"
                      aria-hidden="true"
                      class="bi bi-x-lg btn-action-file"
                      title="Abandonner les changements"
                      (click)="resetUserModification()"
                      (keypress)="resetUserModification()">
                    </i>
                  </td>
                } @else {
                  <td>
                    <i tabIndex="0" aria-hidden="true" class="bi bi-pencil btn-action-file" title="Renommer" (click)="modifyUser($index)" (keypress)="modifyUser($index)"> </i>
                  </td>
                }
              </tr>
            }
          </table>
        }
        @case (enumAdministrationType.ModuleCustom) {
          @if (modeCreation) {
            <div class="form-container">
              <h3>Nouveau module spécial</h3>
              <form [formGroup]="newModuleCustomForm">
                <label htmlFor="moduleKey">Clef du module</label>
                <input pInputText id="moduleKey" formControlName="moduleKey" />
                <label htmlFor="name">Nom du module</label>
                <input pInputText id="name" formControlName="name" />
              </form>
              <button class="button margin-left" (click)="createModuleCustom()">Créer</button>
            </div>
          } @else {
            <button class="button" (click)="modeCreation = true">Créer un module spécial</button>
          }
          <form [formGroup]="moduleCustomForm">
            <button class="button" (click)="saveModuleAssociations()">Sauvegarder</button>
            @if (moduleCustomList.length > 0) {
              <table>
                <thead class="membership-tiers text-left">
                  <tr>
                    <th>Nom</th>
                    <th>Clé</th>
                    @for (project of projects; track $index; let columnIndex = $index) {
                      <th class="rotated-text" scope="col">{{ project.identificationName }}</th>
                    }
                  </tr>
                </thead>

                <tbody formArrayName="moduleCustomRows">
                  @for (module of moduleCustomList; let rowIndex = $index; track rowIndex) {
                    <tr [formGroupName]="rowIndex">
                      <td>{{ module.name }}</td>
                      <td>{{ module.moduleKey }}</td>
                      @for (project of projects; let columnIndex = $index; track $index) {
                        <td>
                          <div class="checkbox" formArrayName="columns">
                            <p-checkbox [binary]="true" [formControlName]="columnIndex" (click)="messageCheckbox(rowIndex, columnIndex)"></p-checkbox>
                          </div>
                        </td>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            } @else {
              <p>Aucun module n'a été créé.</p>
            }
          </form>
        }
        @case (enumAdministrationType.Languages) {
          @if (languages.length > 0) {
            <table>
              <tr>
                <th>Id</th>
                <th>Langue</th>
                <th>Code</th>
              </tr>
              @for (language of languages; track $index) {
                <tr>
                  <td class="table-user-item">{{ language.id }}</td>
                  <td class="table-user-item">{{ language.name }}</td>
                  <td class="table-user-item">{{ language.code }}</td>
                </tr>
              }
            </table>
          } @else {
            <p>Aucune langue n'est associée.</p>
          }
        }
      }
    </div>
  </div>
</div>

<app-module-custom-popup
  [visible]="visiblePopup"
  [moduleCustomAssociations]="modifications"
  [projects]="projects"
  [modules]="moduleCustomList"
  (confirmActionEmitter)="processModuleAssociations($event)">
</app-module-custom-popup>
