<div class="navbar" [ngClass]="extended ? 'navbar-extended' : ''">
  <div>
    <div class="logo-container" [ngClass]="extended ? 'logo-container-extended' : ''">
      @if (extended) {
        <div class="menu-text">Menu</div>
        <button aria-label="Replier le menu" class="button bi bi-arrow-left" (click)="setExtended(false)" title="Replier le menu"></button>
      } @else {
        <button aria-label="Étendre le menu" class="button bi bi-list" (click)="setExtended(true)" title="Étendre le menu"></button>
      }
    </div>

    <div class="navbar-nav">
      <a routerLink="/{{ this.getProjectName() }}/home" title="Page d'accueil" class="navbar-nav-item-link" routerLinkActive="active">
        <i class="bi bi-house"></i>
        @if (extended) {
          <span class="navbar-link-text">Accueil</span>
        }
      </a>

      <a routerLink="/{{ this.getProjectName() }}/area-management" title="Page de gestion de l'espace" class="navbar-nav-item-link" routerLinkActive="active">
        <i class="bi bi-cursor"></i>
        @if (extended) {
          <span class="navbar-link-text">Parcours</span>
        }
      </a>

      <a routerLink="/{{ this.getProjectName() }}/module-management" title="Bibliothèque d'activités" class="navbar-nav-item-link" routerLinkActive="active">
        <i class="bi bi-puzzle"></i>
        @if (extended) {
          <span class="navbar-link-text">Activités</span>
        }
      </a>

      <a routerLink="/{{ this.getProjectName() }}/document-library" title="Bibliothèque de médias" class="navbar-nav-item-link" routerLinkActive="active">
        <i class="bi bi-collection"></i>
        @if (extended) {
          <span class="navbar-link-text">Bibliothèque</span>
        }
      </a>

      @if (userType === "Administrator") {
        <a routerLink="/{{ this.getProjectName() }}/administration" title="Page d'administration" class="navbar-nav-item-link" routerLinkActive="active">
          <i class="bi bi-gear"></i>
          @if (extended) {
            <span class="navbar-link-text">Administration</span>
          }
        </a>
      }
    </div>
  </div>

  <div>
    <a routerLink="/{{ this.getProjectName() }}/changelog" title="Changelog" class="navbar-nav-item-link" routerLinkActive="active">
      <i class="bi bi-collection"></i>
      @if (extended) {
        <span class="navbar-link-text">Changelog</span>
      }
    </a>

    @if (this.getProjectUserCount() > 1) {
      <a routerLink="project-selection" title="Choisir un projet" class="navbar-nav-item-link" routerLinkActive="active">
        <i class="bi bi-clipboard2-check"></i>
        @if (extended) {
          <span class="navbar-link-text">Projets</span>
        }
      </a>
    }

    <div title="Se déconnecter" tabIndex="0" aria-hidden="true" class="navbar-nav-item-link" (click)="logout()" (keypress)="logout()">
      <i class="bi bi-box-arrow-right"></i>
      @if (extended) {
        <span class="navbar-link-text">Se déconnecter</span>
      }
    </div>
  </div>
</div>
