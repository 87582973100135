<div class="body">
  <div class="container">
    <form [formGroup]="loginForm">
      <h1 id="login-text">Login</h1>
  
      <div class="form-field">
        <span class="p-float-label">
          <input pInputText id="email" formControlName="email" />
          <label htmlFor="email">E-mail</label>
        </span>
      </div>
      <div class="form-field">
        <span class="p-float-label">
          <p-password formControlName="password" [feedback]="false" [toggleMask]="true"></p-password>
          <label for="password">Mot de passe</label>
        </span>
      </div>
      <div class="form-button">
        <button [disabled]="isLogging" class="button" (click)="login()">Login</button>
        <p class="password-forgotten-text" [routerLink]="'/password-forgotten'">Mot de passe oublié ?</p>
        @if (isLogging) {
          <p-progressSpinner class="spinner"></p-progressSpinner>
        }
      </div>
    </form>
  </div>
</div>
