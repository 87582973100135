import { Project } from "./project";

export class User {
  id!: number;
  firstName!: string;
  surname!: string;
  email: string;
  role!: string;
  emailConfirmed!: boolean;
  passwordSet?: boolean;
  projectIdList?: number[];
  projects: Project[] = [];

  constructor(id: number, email: string, firstName: string, surname: string, role: string, projectIdList?: number[]) {
    this.id = id;
    this.firstName = firstName;
    this.surname = surname;
    this.email = email;
    this.role = role;
    this.projectIdList = projectIdList;
  }
}
