<div class="card">
  <form [formGroup]="informationsForm">
    <div class="header">
      <div class="horizontal-display">
        <h2 title="Cette section sert à configurer le parcours.">Configuration du parcours<i class="bi bi-question-circle infos-title-section margin-left-small"></i></h2>
      </div>
    </div>

    <div class="horizontal-display">
      <div class="form-field">
        <div style="width: 100%">
          <label htmlFor="itineraryLabel">Label du parcours</label>
          <p>Choisissez un nom qui ne sera visible que par vous pour identifier le parcours</p>
          <div>
            <input #itineraryLabel pInputText id="itineraryLabel" formControlName="itineraryLabel" [maxlength]="inputLength.maxItineraryLabel" />
          </div>
          <small class="char-count"> ({{ countLabelCharacters() }}/{{ inputLength.maxItineraryLabel }}) </small>
        </div>

        <div class="margin-top">
          <label htmlFor="latitude">Localisation</label>
          <p>Choisissez si les étapes de ce parours seront localisables ou non.</p>
          <p-inputSwitch formControlName="isLocated" title="Activer/désactiver la localisation"></p-inputSwitch>
        </div>
      </div>

      <div class="form-field">
        <span class="">
          <div class="horizontal-display">
            <div>
              <label htmlFor="languages">Langue(s) du parcours</label>
              <p>Choisissez les langues qui seront disponibles sur la tablette de l'utilisateur</p>
            </div>
            <div>
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-plus-circle-dotted action-buttons-item"
                title="Ajouter des langues"
                (click)="visibleLanguages = true"
                (keypress)="visibleLanguages = true">
              </i>
            </div>
          </div>

          @for (language of selectedLanguages; track $index) {
            <div class="languages-selected">
              <p class="added-language">{{ language.name }}</p>
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-trash action-buttons-item"
                title="Supprimer la langue {{ language.name }}"
                (click)="showPopupLanguageDeletion(language.id); $event.stopPropagation()"
                (keypress)="showPopupLanguageDeletion(language.id); $event.stopPropagation()">
              </i>
            </div>
          }
          @if (selectedLanguages.length === 0) {
            <p class="added-language">Aucune langue n'est associée au parcours.</p>
          }
        </span>
      </div>
    </div>
  </form>
  @if (isLocated()) {
    @if (showMap) {
      <div style="margin-top: 20px"></div>
      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier la carte" (click)="extendMap(false)" (keypress)="extendMap(false)">Cacher la carte</i>
      <div style="margin-bottom: 20px"></div>
      <app-point-of-interest-map [pointsOfInterest]="itinerary?.pointOfInterests" [isPageItinerary]="true" (changeCoordinates)="this.changeCoordinates.emit($event)"> </app-point-of-interest-map>
    } @else {
      <div style="margin-top: 20px"></div>
      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier la carte" (click)="extendMap(true)" (keypress)="extendMap(true)">Afficher la carte</i>
    }
  }
</div>

<app-add-language-popup
  [availableLanguages]="languagesList"
  [visible]="visibleLanguages"
  [selectedLanguages]="selectedLanguages"
  [languageIdToDelete]="languageIdToDelete"
  (confirmActionEmitter)="languagesChanged($event)">
</app-add-language-popup>
